import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from './views/Home'
import About from './views/About'
import Events from './views/Events'
import Contact from './views/Contact'

const Routes = () => {
  return (
    <main className="main">
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route exact path="/about" component={About}/>
        <Route exact path="/events" component={Events}/>
        <Route exact path="/contact" component={Contact}/>
      </Switch>
    </main>
  )
}

export default Routes